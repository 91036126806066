<template>

      <v-app class>
        <div>
        <v-alert
      v-model="alert"
      border="left"
      dark
      dismissible
      class="alert"
      :class="{'alert-error':alertType=='error', 'alert-success':alertType=='success'}"
    >
    {{alertMessage}}
        </v-alert>
        </div>
        <div class="row">
          <div class="col-md-7 ml-auto mr-auto">
            <v-container>
              <div class="mb-8 mt-8 text-center">
                <h1>Holiday Approval</h1>
              </div>
               <v-data-table
              :headers= 'headers'
              :items="holidays"
             >
                <template v-slot:item.actions="{ item }" >
                    <div class="d-flex">
                      <Button :btnType="'Submit'" :label="'APprove'" @onClick="openConfiModal(item)" class="mr-2"/>
                    <Button :btnType="'Cancel'" :label="'Reject'" @onClick="openCancelModal(item)" />
                    </div>
              </template>
          </v-data-table>

           <ConfirmationDialog :title="'Are you sure you want to submit?'" :isLoading="isLoading" :dialog="dialog" :btnTitle="'Yes'" @close="dialog = false" @btnAction="approve"/>
            </v-container>
          </div>
        </div>
      </v-app>

</template>
<script>
import Button from '@/components/ui/form/Button.vue'
import ConfirmationDialog from '@/components/ui/confirmationDialog.vue'
import { FETCH_PENDING_HOLIDAY, APPROVE_HOLIDAY } from '@/store/action-type'

export default {
  components: {
    Button,
    ConfirmationDialog
  },
  data () {
    return {
      search: '',
      alertMessage: '',
      alert: false,
      alertType: '',
      dialog: false,
      isLoading: false,
      item: null,
      headers: [
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'National',
          value: 'isNational'
        },
        {
          text: 'Actions',
          value: 'actions'
        }
      ]
    }
  },
  computed: {
    holidays () {
      return this.$store.getters.pendingHolidays
    }
  },
  methods: {
    showAlertMessage (message, type) {
      this.alertMessage = message
      this.alert = true
      this.alertType = type
    },
    approve () {
      const data = {
        status: 1,
        id: this.item.id,
        comments: 'Valid'
      }
      this.isLoading = true
      this.$store
        .dispatch(APPROVE_HOLIDAY, data)
        .then((result) => {
          this.showAlertMessage('Operation successful', 'success')
        })
        .catch((error) => {
          console.log(error)
          this.showAlertMessage('Operation failed', 'error')
        }).finally(() => {
          this.isLoading = false
          this.dialog = false
        })
    },
    reject (item) {
      item.status = 2

      console.log(item)
      this.$store
        .dispatch(APPROVE_HOLIDAY, item)
        .then((result) => {
          this.showAlertMessage('Reject Holiday successful', 'success')
          // eslint-disable-next-line handle-callback-err
        })
        .catch((error) => {
          console.log(error)
          this.showAlertMessage('Reject Holiday failed', 'error')
        })
    },
    openConfiModal (item) {
      this.item = item
      this.dialog = true
    }
  },
  mounted () {
    this.$store
      .dispatch(FETCH_PENDING_HOLIDAY)
      .then(() => {})
      .catch((error) => {
        console.log(error)
        this.showAlertMessage('There are no items pending your approval', 'warning')
      })
  }
}
</script>
<style scoped>
.search-btn {
  background-color: #e6e3e3;
  height: 40px;
  border-radius: 3px !important;
}
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
.alert {
  position: fixed;
  z-index: 9999;
  top: 5px;
  min-width: 50%;
}
.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-error {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
</style>
